import { useState } from "react";
import { useTranslation } from "react-i18next";
import authenticationService, {
  OpenUpRoles,
} from "@src/services/authentication.service";
import { useAllSessionsQuery } from "@src/queries/experts";
import SessionsView from "./SessionsView";

let columns = [
  {
    id: "AllSessions.Date",
    minWidth: 130,
  },
  {
    id: "AllSessions.Client",
    minWidth: 250,
  },
  {
    id: "AllSessions.Session",
    minWidth: 116,
  },
  {
    id: "AllSessions.Type",
    minWidth: 125,
  },
  {
    id: "AllSessions.Psychologist",
    minWidth: 100,
  },
  {
    id: "",
    minWidth: 50,
  },
  {
    id: "AllSessions.Status",
    minWidth: 250,
  },
];

const AllSessionsView = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");

  const { data, isFetching } = useAllSessionsQuery({ page, search });

  const {
    data: consults,
    pagination: { total, hasNextPage },
  } = data;

  const { isManagerOrAdmin, isManagerOrAdminOrHost, hasRole } =
    authenticationService;
  if (!isManagerOrAdminOrHost()) {
    // remove reassign column
    columns = columns.filter((col) => col.id !== "");
  }
  const isSupport = hasRole(OpenUpRoles.Support);
  const showReferralToolkit = false;
  return (
    <SessionsView
      titleText={t("AllSessions.PageTitle")}
      placeholderSearch={t("AllSessions.List.SearchPlaceholder")}
      page={page}
      setPage={setPage}
      setSearch={setSearch}
      columns={columns}
      isFetching={isFetching}
      consults={consults}
      total={total}
      hasNextPage={hasNextPage}
      showPsychologistName
      showReassignButton={isManagerOrAdminOrHost()}
      showJoinSession={isManagerOrAdmin()}
      showRescheduleSession={isManagerOrAdminOrHost()}
      showCancelSession={isManagerOrAdminOrHost()}
      showSessionDetails={isManagerOrAdmin()}
      showChangeSessionStatus={isSupport}
      showCopyJoinLink={isManagerOrAdminOrHost()}
      showReferralToolkitIcon={showReferralToolkit}
    />
  );
};

export default AllSessionsView;
