import { FC } from "react";
import {
  Dialog,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { STATUS } from "@src/constants";
import { useFormik } from "formik";
import { Consult } from "@src/models/Consult";
import { useTranslation } from "react-i18next";
import { useConsultMutation } from "@src/queries/consults";
import moment from "moment";
import { DialogHeader, DialogActionButtons } from "../DialogToolkit";
import { WithSnackbarProps, withSnackbar } from "../SnackBarComponent";

const useStyles = makeStyles({
  form: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  formItem: {
    flexBasis: "calc(50% - 12px)",
    height: 72,
  },
});
interface ChangeSessionStatusDialogProps extends WithSnackbarProps {
  consult: Consult | undefined;
  setOpen: (open: boolean) => void;
  open: boolean;
}
const ChangeSessionStatusDialog: FC<ChangeSessionStatusDialogProps> = ({
  consult,
  snackbarShowMessage,
  setOpen,
  open = false,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    updateConsultStatus: { mutateAsync: updateConsultStatus },
  } = useConsultMutation(consult?.id);
  const formik = useFormik({
    initialValues: {
      ...consult,
      status: consult?.status,
    },
    onSubmit: async (values: Consult) => {
      try {
        formik.setSubmitting(true);
        const response = await updateConsultStatus({ ...values });
        if (values.status === STATUS.COMPLETED)
          snackbarShowMessage(
            t("AllSessions.StatusChangedAndNotesAvailable") +
              moment(response.data.notesAvailableAt).format("DD MMMM YY hh:mm"),
            "success",
            5000,
          );
        else
          snackbarShowMessage(t("AllSessions.StatusChanged"), "success", 3000);
      } catch (e) {
        if (e.response.data.errors === undefined) {
          throw e;
        }

        for (const [key, value] of Object.entries(e.response.data.errors)) {
          snackbarShowMessage(`${key}: ${value}`);
        }
      } finally {
        formik.setSubmitting(false);
        setOpen(false);
      }
    },
    enableReinitialize: true,
  });
  if (!consult) return <></>;

  return (
    <Dialog
      onClose={(e) => {
        formik.handleReset(e);
        setOpen(false);
      }}
      open={open}
    >
      <DialogHeader
        iconLigature="sync_alt"
        title={t("AllSessions.ChangeStatus")}
        outlined
        onDelete={undefined}
        onFilter={undefined}
        textRightButton={undefined}
      />

      <form onSubmit={formik.handleSubmit} className={classes.form} noValidate>
        <FormControl variant="standard" className={classes.formItem} required>
          <InputLabel id="status-label">{t("Common.Status")}</InputLabel>
          <Select
            variant="standard"
            name="status"
            labelId="status-label"
            id="status"
            onChange={formik.handleChange}
            value={formik.values.status}
            data-cy="status-select"
          >
            {Object.entries(STATUS).map(([value, label]) => (
              <MenuItem key={value} value={label}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </form>
      <DialogActionButtons
        onCancel={(e) => {
          formik.handleReset(e);
          setOpen(false);
        }}
        onSubmit={formik.submitForm}
        onCancelText={undefined}
        onSubmitText={t("Common.Save")}
        disabled={undefined}
      />
    </Dialog>
  );
};

export default withSnackbar(ChangeSessionStatusDialog);
