import {
  AttachmentUploadOptions,
  AttachmentUploadTask,
} from "@azure/communication-react";
import { apiPathsWithParams } from "@src/constants";
import axios from "axios";
import FormData from "form-data";

const SUPPORTED_FILES = [
  "png",
  "jpg",
  "jpeg",
  "gif",
  "bmp",
  "pdf",
  "doc",
  "docx",
];

const uploadChatFile = async (
  task: AttachmentUploadTask,
  chatThreadId: string,
  uploadFailedMessage: string,
) => {
  const formData = new FormData();
  formData.append("file", task.file, task.file?.name);
  const fileExtension = task.file?.name.split(".").pop() ?? "";
  if (!SUPPORTED_FILES.includes(fileExtension)) {
    task.notifyUploadFailed(
      `Uploading ".${fileExtension}" files is not allowed.`,
    );
    return;
  }
  try {
    const response = await axios
      .request({
        method: "post",
        url: apiPathsWithParams.uploadChatFile(chatThreadId),
        data: formData,
        headers: {
          "Content-Type": `multipart/form-data`,
        },
        onUploadProgress: (p) => {
          if (p.total) task.notifyUploadProgressChanged(p.loaded / p.total);
        },
      })
      .catch((e) => {
        const errors = Object.values<[]>(e.response?.data?.errors).flat(2) as {
          message: string;
        }[];
        if (errors.length === 0) task.notifyUploadFailed(uploadFailedMessage);
        else
          errors.forEach((error) => {
            task.notifyUploadFailed(error.message);
          });
      });
    // 1 means the file upload progress is 100%. Similarly, 0.5 would be 50%.
    task.notifyUploadProgressChanged(1);
    task.notifyUploadCompleted(task.file?.name ?? "", response?.data.url);
  } catch (error) {
    task.notifyUploadFailed(uploadFailedMessage);
    throw error;
  }
};

export const getAcsFileUploadOptions: (
  chatThreadId: string,
  uploadFailedMessage: string,
) => AttachmentUploadOptions = (
  chatThreadId: string,
  uploadFailedMessage: string,
) => ({
  disableMultipleUploads: false,
  handleAttachmentSelection: async (tasks: AttachmentUploadTask[]) => {
    tasks.forEach(async (task) => {
      await uploadChatFile(task, chatThreadId, uploadFailedMessage);
    });
  },
});
