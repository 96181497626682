import {
  AttachmentDownloadOptions,
  AttachmentMenuAction,
  AttachmentMetadata,
  defaultAttachmentMenuAction,
} from "@azure/communication-react";

import { getApiBaseUrl } from "@src/utils/urlHelpers";

export const openuFileUrlHandler = (): AttachmentMenuAction[] => [
  {
    name: "Download",
    icon: defaultAttachmentMenuAction.icon,
    onClick: (attachment: AttachmentMetadata): Promise<void> => {
      return new Promise<void>((resolve) => {
        window.open(
          new URL(attachment.url, getApiBaseUrl()),
          "_blank",
          "noopener,noreferrer",
        );
        resolve();
      });
    },
  },
];

export const acsDownloadOptions: AttachmentDownloadOptions = {
  actionsForAttachment: openuFileUrlHandler,
};
