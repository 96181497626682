import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { useAuthentication } from "@src/services/authentication.service";
import { ExpertDetails } from "@src/models/ExpertDetails";
import { apiPaths, pageSize } from "@src/constants";
import {
  ConsultFeedback,
  PaginatedResult,
  GetPsychologistConsultsResponse,
} from "@src/models";
import { fiveMinutes, oneHour } from "./staletimes";
import { queryIdentifiers } from "../utils/constants";

export const useReassignPsychologistMutation = () => {
  const queryClient = useQueryClient();
  const onSuccess = () => {
    queryClient.invalidateQueries([queryIdentifiers.consults]);
    queryClient.invalidateQueries([queryIdentifiers.psychologistManagement]);
  };

  return useMutation(
    ({ userId, consultId }: { userId: string; consultId: string }) =>
      axios.put(`${apiPaths.reassignPsychologist}/${userId}/${consultId}`),
    {
      onSuccess,
    },
  );
};

export const usePsychologistMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (userId) => axios.put(`${apiPaths.makePsychologist}/${userId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryIdentifiers.psychologists]);
      },
    },
  );
};

export const useOpenUpEmployeeQuery = (queryParams = {}) =>
  useQuery(
    [queryIdentifiers.employee, queryParams],
    async ({ queryKey: [url, params] }) => {
      const { data } = await axios.get(`${apiPaths.openupEmployees}`, {
        params,
      });
      return data;
    },
  );

export const usePsychologistsQuery = (
  queryParams = {},
  tagsAndThemes = { tags: [], themes: [] },
) =>
  useQuery(
    [queryIdentifiers.psychologists, queryParams, tagsAndThemes],
    async ({ queryKey: [url, params, tagsAndThemes] }) => {
      const { data } = await axios.post(apiPaths.psychologists, tagsAndThemes, {
        params,
      });
      return data;
    },
    {
      staleTime: fiveMinutes,
    },
  );

export const usePsychologistQuery = (psychologistId) =>
  useQuery(
    [queryIdentifiers.psychologists, psychologistId],
    async () => {
      const { data } = await axios.get(
        `${apiPaths.psychologists}/${psychologistId}`,
      );
      return data;
    },
    {
      staleTime: fiveMinutes,
    },
  );

export const usePsychologistThemesQuery = (psychologistId) => {
  const queryClient = useQueryClient();

  const { data: psychologistThemes } = useQuery(
    [queryIdentifiers.psychologists, "psychologistThemes", psychologistId],
    async () => {
      const { data } = await axios.get(
        `${apiPaths.psychologistThemes}/${psychologistId}`,
      );
      return data;
    },
  );

  const { mutateAsync: savePsychologistThemes } = useMutation(
    async (themeKeys) =>
      axios.post(
        `${apiPaths.setPsychologistTheme}/${psychologistId}`,
        themeKeys,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          queryIdentifiers.psychologistManagement,
          "psychologistThemes",
          psychologistId,
        ]);
      },
    },
  );

  return {
    psychologistThemes,
    savePsychologistThemes,
  };
};

export const usePsychologistsConsultQuery = (consultId) => {
  const { isLoggedIn } = useAuthentication();

  return useQuery(
    [queryIdentifiers.psychologists, queryIdentifiers.consults, consultId],
    async () => {
      const { data } = await axios.get(
        `${apiPaths.psychologistConsults}/${consultId}`,
      );
      return data;
    },
    {
      enabled: isLoggedIn,
      staleTime: oneHour,
    },
  );
};

export const useExpertsConsultsQuery = (queryParams = {}) => {
  const queryClient = useQueryClient();
  const { isLoggedIn } = useAuthentication();

  return useQuery(
    [queryIdentifiers.psychologists, queryParams],
    async ({ queryKey: [url, params] }) => {
      const { data } = await axios.get<
        PaginatedResult<GetPsychologistConsultsResponse>
      >(`${apiPaths.psychologistConsults}`, {
        params,
      });
      return data;
    },
    {
      enabled: isLoggedIn,
      staleTime: fiveMinutes,
      onSuccess: ({ data: consults }) => {
        consults.forEach((consult) =>
          queryClient.setQueryData(
            [
              queryIdentifiers.psychologists,
              queryIdentifiers.consults,
              consult.id,
            ],
            consult,
          ),
        );
      },
    },
  );
};

export const useAllSessionsQuery = (queryParams = {}) => {
  const { isLoggedIn } = useAuthentication();

  return useQuery(
    [queryIdentifiers.consults, queryParams],
    async ({ queryKey: [url, params] }) => {
      const { data } = await axios.get(`${apiPaths.psychologistAllConsults}`, {
        params,
      });
      return data;
    },
    {
      enabled: isLoggedIn,
      staleTime: fiveMinutes,
    },
  );
};

export const useLoggedPsychologist = () => {
  return useQuery(
    [queryIdentifiers.psychologists, queryIdentifiers.profile],
    async () => {
      const { data } = await axios.get(`${apiPaths.psychologistProfile}`);
      return data;
    },
    {
      staleTime: oneHour,
    },
  );
};

export const useExpertDetailsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ userId, worksTwentyFourSeven }: ExpertDetails) =>
      axios.patch<ExpertDetails>(
        `${apiPaths.expertDetails}/${userId}`,
        new ExpertDetails({ worksTwentyFourSeven }),
      ),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryIdentifiers.psychologists],
      });
    },
  });
};

export const useExpertFeedbackQuery = (
  page: number = 0,
  limit: number = pageSize,
  search: string = "",
) => {
  return useQuery(
    [apiPaths.myFeedbacks, page, limit, search],
    async () => {
      const { data } = await axios.get<PaginatedResult<ConsultFeedback>>(
        `${apiPaths.myFeedbacks}`,
        {
          params: {
            page,
            limit,
            search,
          },
        },
      );
      return data;
    },
    {
      staleTime: fiveMinutes,
    },
  );
};
