import { alpha, FormControl, MenuItem, Select, Badge } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { FC } from "react";
import {
  OpenUpRoles,
  useAuthentication,
} from "@src/services/authentication.service";
import { useLoggedPsychologist } from "@src/queries/experts";
import { PROFILE_VALUES } from "@src/constants";
import { colors } from "@src/theme";
import { withSuspense } from "./wrappers/Suspendable";
import { TypographyWithIcon } from "./utils/TypographyWithIcon";

const useStyle = makeStyles((theme) => ({
  formControl: {
    display: "flex",
    alignItems: "center",
    "& > .MuiInputBase-root": {
      paddingLeft: 8,
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  select: {
    paddingLeft: theme.spacing(2),
    "&:hover": {
      backgroundColor: alpha(colors.primaryBlue, 0.1),
    },
    "&:focus": {
      backgroundColor: "unset",
    },
  },
  icon: {
    color: colors.primaryDarkBlue,
    left: 0,
    marginTop: theme.spacing(0.25),
    width: 20,
    height: 20,
  },
  typographyClass: {
    fontSize: 16,
    "&:hover": {
      color: colors.secondaryBlue,
    },
  },
  iconOpen: {
    transform: "rotate(0deg)",
  },
}));

const UserSettings: FC = () => {
  const { data: userProfile } = useLoggedPsychologist();

  const classes = useStyle();
  const { logout, hasRole } = useAuthentication();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const hasAnyRoleExceptSupportAndHost =
    hasRole(OpenUpRoles.Expert) ||
    hasRole(OpenUpRoles.Manager) ||
    hasRole(OpenUpRoles.Admin);

  const handleChange = (e) => {
    switch (e.target.value) {
      case PROFILE_VALUES.LOGOUT: {
        logout();
        navigate("/login");
        break;
      }
      case PROFILE_VALUES.PROFILE: {
        navigate("/profile");
        break;
      }
      default: {
        navigate("/");
        break;
      }
    }
  };

  return (
    <FormControl variant="standard" className={classes.formControl}>
      <Select
        variant="standard"
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value=""
        defaultValue=""
        onChange={handleChange}
        displayEmpty
        IconComponent="span"
        classes={{
          select: classes.select,
          icon: classes.icon,
          iconOpen: classes.iconOpen,
        }}
        sx={{
          "&:before": {
            display: "none",
          },
        }}
        renderValue={() => {
          return (
            <Badge
              badgeContent={" "}
              invisible={
                !hasRole(OpenUpRoles.Expert) || userProfile.hasLinkedCalendar
              }
              variant="dot"
              color="error"
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <TypographyWithIcon
                iconLigature="account_circle"
                outlined
                variant="subtitle1"
                typographyClass={classes.typographyClass}
                mb={0}
              >
                {userProfile.firstName}
              </TypographyWithIcon>
            </Badge>
          );
        }}
      >
        {hasAnyRoleExceptSupportAndHost && (
          <MenuItem
            key="Profile"
            value={PROFILE_VALUES.PROFILE}
            data-cy="profile-menu-item"
          >
            <Badge
              badgeContent={" "}
              invisible={
                !hasRole(OpenUpRoles.Expert) || userProfile.hasLinkedCalendar
              }
              variant="dot"
              color="error"
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <TypographyWithIcon
                iconLigature="account_circle"
                outlined
                mb={0}
                typographyClass={classes.typographyClass}
                variant="subtitle1"
              >
                {t("ProfileView.Profile")}
              </TypographyWithIcon>
            </Badge>
          </MenuItem>
        )}
        <MenuItem key="Logout" value={PROFILE_VALUES.LOGOUT}>
          <TypographyWithIcon
            iconLigature="logout"
            outlined
            mb={0}
            typographyClass={classes.typographyClass}
            variant="subtitle1"
          >
            {t("Navigation.Logout")}
          </TypographyWithIcon>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default withSuspense(UserSettings);
