import { Container, Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PersonAddOutlined from "@mui/icons-material/PersonAddOutlined";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  OpenUpRoles,
  useAuthentication,
} from "@src/services/authentication.service";
import AddOrUpdateClientDialog from "@src/components/AddOrUpdateClientDialog";
import { useLoggedPsychologist } from "@src/queries/experts";
import { colors } from "@src/theme";

import BackgroundImage from "./dashboard_home_background.svg";

const useStyles = makeStyles(({ spacing }) => ({
  title: {
    color: "white",
    marginTop: spacing(4),
    textAlign: "center",
  },
  subTitle: {
    color: "white",
    textAlign: "center",
    marginTop: spacing(3),
  },
  gridClass: {
    margin: spacing(0),
    marginTop: spacing(8),
  },
  imageItem: {
    display: "flex",
    justifyContent: "center",
  },
  paperWrapper: {
    backgroundColor: colors.primaryGreen,
    boxShadow: "none",
    border: "1px solid white",
    borderStyle: "dashed",
    maxWidth: 250,
    cursor: "pointer",
    padding: spacing(4, 3),
  },
  iconPaper: {
    color: "white",
    fontSize: "22px",
  },
  paperText: {
    color: "white",
    marginTop: spacing(1),
  },
  paperTitle: {
    color: "white",
    marginTop: spacing(2),
  },
}));

const HomeView: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const { data: userProfile } = useLoggedPsychologist();
  const { hasRole } = useAuthentication();
  const canAddClient =
    hasRole(OpenUpRoles.Expert) ||
    hasRole(OpenUpRoles.Manager) ||
    hasRole(OpenUpRoles.Host) ||
    hasRole(OpenUpRoles.Admin);
  return (
    <Container maxWidth="xl">
      <Typography variant="h1" className={classes.title}>
        {t("DashboardView.Title", { key: userProfile.firstName })}
      </Typography>
      <Typography variant="body1" className={classes.subTitle}>
        {t("DashboardView.Text")}
      </Typography>
      <Grid container className={classes.gridClass}>
        <Grid item xs={9} className={classes.imageItem}>
          <img
            src={BackgroundImage}
            alt="background logo of woman using a dashboard"
          />
          ,
        </Grid>
        <Grid item xs={3}>
          {canAddClient && (
            <Paper
              data-cy="add-client-button"
              className={classes.paperWrapper}
              onClick={() => setOpen(true)}
            >
              <PersonAddOutlined className={classes.iconPaper} />
              <Typography variant="h6" className={classes.paperTitle}>
                {t("DashboardView.AddClient")}
              </Typography>
              <Typography variant="body1" className={classes.paperText}>
                {t("DashboardView.AddClientText")}
              </Typography>
            </Paper>
          )}
        </Grid>
      </Grid>
      <AddOrUpdateClientDialog open={open} onClose={() => setOpen(false)} />
    </Container>
  );
};

export default HomeView;
