import {
  AzureCommunicationTokenCredential,
  CommunicationUserIdentifier,
} from "@azure/communication-common";
import {
  fromFlatCommunicationIdentifier,
  useAzureCommunicationCallWithChatAdapter,
  CallAndChatLocator,
  CallWithChatComposite,
  COMPOSITE_LOCALE_EN_GB,
  COMPOSITE_LOCALE_NL_NL,
  AzureCommunicationCallWithChatAdapterArgs,
  onResolveVideoEffectDependency,
  CallWithChatAdapter,
  CallWithChatAdapterState,
} from "@azure/communication-react";
import {
  WithSnackbarProps,
  withSnackbar,
} from "@src/components/SnackBarComponent";
import { RESPONSE_STATUS } from "@src/constants";
import { VideoCallingToken } from "@src/models/VideoCallingToken";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getToken, whoElseIsInCall } from "@src/queries/video-calling";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getAcsFileUploadOptions } from "@src/utils/acsFileUploadHandler";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { initializeIcons } from "@uifabric/icons";
import { getConsultById } from "@src/queries/consults";
import { acsDownloadOptions } from "@src/utils/acsFileDownloadHandler";
import useSound from "use-sound";
import joinedSound from "@src/resources/audio/participant-joined.wav";

initializeIcons();

const MakePsychologistCall: React.FC<WithSnackbarProps> = ({
  snackbarShowMessage,
}) => {
  const { consultId, clientId } = useParams();
  const { t, i18n } = useTranslation();
  const [token, setToken] = useState<VideoCallingToken | null>();
  const [inCallNames, setInCallNames] = useState<string | undefined>(undefined);
  const [showInCallNames, setShowInCallNames] = useState(false);
  const appInsights = useAppInsightsContext();
  const [play] = useSound(joinedSound);
  const isDefaultConfigSet = useRef(false);
  const [consult, setConsult] = useState<any>(undefined);

  useEffect(() => {
    const fetchData = async () => {
      if (!clientId || !consultId) return undefined;
      const response = await getConsultById(clientId, Number(consultId));
      setConsult(response);
      return response;
    };

    fetchData();
  }, [clientId, consultId]);

  const getAcsLocale = () => {
    switch (i18n.language) {
      case "en-GB":
        return COMPOSITE_LOCALE_EN_GB;
      case "nl-NL":
        return COMPOSITE_LOCALE_NL_NL;
      default:
        return COMPOSITE_LOCALE_EN_GB;
    }
  };
  useEffect(() => {
    (async () => {
      if (!consult?.roomId) return;
      const getTokenTask = getToken(consult.roomId).catch((e) => {
        if (e.response.status === RESPONSE_STATUS.BAD_REQUEST) {
          snackbarShowMessage(e.response.data, "error");
        }
        return undefined;
      });
      const whoElseIsInCallTask = whoElseIsInCall(consult.roomId);
      const result = await Promise.all([getTokenTask, whoElseIsInCallTask]);

      setToken(result[0]);
      setInCallNames(result[1]?.map((x) => x.name).join(", "));
      setShowInCallNames(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consult?.roomId]);
  if (consult?.canJoinVideoCall === false)
    snackbarShowMessage(t("MakePsychologistCall.ExpiredSession"), "error");

  // A well-formed token is required to initialize the chat and calling adapters.
  const credential = useMemo(() => {
    if (!token) return undefined;
    try {
      return new AzureCommunicationTokenCredential(token.token);
    } catch (err) {
      appInsights.trackException(err);
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const subscribeAdapterEvents = useCallback(
    async (callAdapter: CallWithChatAdapter): Promise<CallWithChatAdapter> => {
      callAdapter.onStateChange(async (state: CallWithChatAdapterState) => {
        switch (state.page) {
          case "configuration":
            if (!isDefaultConfigSet.current) {
              isDefaultConfigSet.current = true;
              await callAdapter?.unmute();
              await callAdapter?.startCamera();
            }
            break;
          case "lobby":
            if (state.call && showInCallNames) {
              setShowInCallNames(false);
            }
            break;
          default:
            break;
        }
      });
      callAdapter.on("callParticipantsJoined", () => {
        play();
      });

      return callAdapter;
    },
    [play, showInCallNames],
  );

  const existingBackgroundImagesCount = 15;
  // Memoize arguments to `useAzureCommunicationCallAdapter` so that
  // a new adapter is only created when an argument changes.
  const callAdapterArgs: AzureCommunicationCallWithChatAdapterArgs = useMemo(
    () => ({
      userId: token
        ? (fromFlatCommunicationIdentifier(
            token?.communicationUserId,
          ) as CommunicationUserIdentifier)
        : undefined,
      displayName: token?.firstName,
      credential: credential ?? undefined,
      endpoint: token?.endpointUrl,
      locator: {
        callLocator: {
          roomId: consult?.roomId,
        },
        chatThreadId: consult?.chatThreadId,
      } as unknown as CallAndChatLocator,
      callAdapterOptions: {
        videoBackgroundOptions: {
          videoBackgroundImages: Array.from(
            { length: existingBackgroundImagesCount },
            (_, index) => {
              const imageNameLength = 3;
              const key = (index + 1).toString().padStart(imageNameLength, "0");
              return {
                key,
                url: `/images/call-backgrounds/${key}.jpg`,
              };
            },
          ),
          onResolveDependency: onResolveVideoEffectDependency,
        },
      },
    }),
    [token, credential, consult?.roomId, consult?.chatThreadId],
  );

  const callAdapter = useAzureCommunicationCallWithChatAdapter(
    callAdapterArgs,
    subscribeAdapterEvents,
  );

  return (
    consult?.canJoinVideoCall && (
      <div className="h-screen flex">
        <div className="flex-grow flex-shrink">
          {callAdapter &&
            showInCallNames &&
            (inCallNames ? (
              <div className="flex justify-center absolute top-8 mt-4 z-10 left-1/2 transform -translate-x-1/2 items-center gap-2 body2">
                <div className="rounded-full bg-slate-200 text-slate-700 w-10 h-10 flex justify-center items-center cap1">
                  {inCallNames[0].toUpperCase()}
                </div>
                {`${inCallNames} ${t("MakePsychologistCall.ClientIsInCall")}`}
              </div>
            ) : (
              <div className="flex justify-center absolute top-8 mt-4 z-10 left-1/2 transform -translate-x-1/2 items-center gap-2 body2">
                {t("MakePsychologistCall.NoClientJoined")}
              </div>
            ))}
          {callAdapter && (
            <CallWithChatComposite
              adapter={callAdapter}
              locale={getAcsLocale()}
              options={{
                attachmentOptions: {
                  uploadOptions: getAcsFileUploadOptions(
                    consult.chatThreadId,
                    t("MakePsychologistCall.UploadFailed"),
                  ),
                  downloadOptions: acsDownloadOptions,
                },
              }}
            />
          )}
          {token && credential === null && (
            <h3>
              Failed to construct credential. Provided token is malformed.
            </h3>
          )}
          {(!callAdapter || !credential) && <h3>Initializing...</h3>}
        </div>
      </div>
    )
  );
};

export default withSnackbar(MakePsychologistCall);
